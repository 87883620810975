import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form, Table } from 'react-bootstrap';


const CurrentOrder = ({ selectedProducts, onAddClickHandler, onRemoveClickHandler }) => {

    const getTotal = () => {
        var total = 0
        selectedProducts.forEach((v, k) => {
            total += (v.prd.priceSell - (v.prd.priceSell * v.discount / 100)) * v.qty
        })
        return total.toFixed(2)
    }

    const setDiscount = (e, mapEntry) => {
        e.preventDefault();
        e.stopPropagation();

        onRemoveClickHandler(mapEntry.prd, mapEntry.discount)
        onAddClickHandler(mapEntry.prd, e.target.value)

        return false;
    }

    return (<>
        <Card className="border border-dark bg-dark-dpool text-white centerElement" key='products' style={{ margin: "10px 0px 10px 0px" }}>
            <Card.Header className="left" style={{ cursor: 'pointer' }}>

            </Card.Header>
            <Card.Body>
                <Table striped bordered hover variant="dark-dpool" className="customer-card-table" responsive>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th style={{ width: "40px" }}>Отстъпка</th>
                            <th style={{ width: "40px" }}>Цена</th>
                            <th style={{ width: "50px" }}>Кол</th>
                            <th style={{ width: "50px" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedProducts.size === 0 &&
                            <tr><td colSpan={4}>Моля, изберете продукт</td></tr>
                        }
                        {[...selectedProducts].sort((e1, e2) => { return e1[0] > e2[0] }).map((mapEntry) => (
                            <tr key={mapEntry[0]} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onRemoveClickHandler(mapEntry[1].prd, mapEntry[1].discount) }} >
                                <td>{mapEntry[1].prd.name}</td>
                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                    <Form.Control id="searchProduct" type="number" min={0} max={100} step={10} value={mapEntry[1].discount}
                                        onChange={(e) => { return setDiscount(e, mapEntry[1]); }} />
                                </td>
                                <td>{mapEntry[1].prd.priceSell}</td>
                                <td>{mapEntry[1].qty}</td>
                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                    <FontAwesomeIcon icon={faPlusSquare} onClick={(e) => { e.preventDefault(); e.stopPropagation(); onAddClickHandler(mapEntry[1].prd) }} />
                                    <br />
                                    <FontAwesomeIcon icon={faMinusSquare} onClick={(e) => {
                                        e.preventDefault(); e.stopPropagation(); onRemoveClickHandler(mapEntry[1].prd, mapEntry[1].discount)
                                    }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <h5 style={{ "float": "right", margin: "20px 20px -10px 0px", fontWeight: "bold", fontSize: "25px" }}>Тотал: {getTotal()}</h5>
            </Card.Body>
        </Card>
    </>)
}

export default CurrentOrder