import { faSave, faSignInAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRef, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import AsyncSelect from 'react-select/async'
import { toast } from 'react-toastify'
import { getCodeTable, storeDatabseEntity } from '../../utils/apiUtils'
import useUser from '../hooks/useUser'
import ActionButton from "../layout/ActionButton"

const Product = () => {

    const location = useLocation();
    const history = useNavigate();

    let prod = location?.state?.prod || {};

    const [product, setProduct] = useState({
        ...prod,
        productType: prod?.productType ? { ...prod.productType, value: prod.productType.id, label: prod.productType.name } : null,
        measureUnit: prod?.measureUnit ? { ...prod.measureUnit, value: prod.measureUnit.id, label: prod.measureUnit.name } : null,
        category: prod?.category ? { ...prod.category, value: prod.category.id, label: prod.category.name } : null,
        displayOrder: prod?.displayOrder ? prod?.displayOrder : 'ZZZZZZ'
    })
    const productRef = useRef(product);

    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState('')
    const { user } = useUser();

    const getUserTimeInfo = (userStr, dateStr) => {
        return userStr + " (" + formatDate(product.dateCreated) + ")"
    }

    const getProductTypes = (inputValue, callback) => {
        getCodeTable("/api/config/productTypes", user, (opts) => {
            if (opts.length === 1) {
                productRef.current.productType = opts[0]
                setProduct({ ...productRef.current })
            }
            callback(opts)
        })
    }

    const getMeasureUnits = (inputValue, callback) => {
        getCodeTable("/api/config/productUnits", user, (opts) => {
            if (opts.length === 1) {
                productRef.current.measureUnit = opts[0]
                setProduct({ ...productRef.current })
            }
            callback(opts)
        })
    }

    const getProductCategories = (inputValue, callback) => {
        getCodeTable("/api/config/productCategories", user, (opts) => {
            if (opts.length === 1) {
                productRef.current.category = opts[0]
                setProduct({ ...productRef.current })
            }
            callback(opts)
        })
    }

    const onSubmit = async (e) => {

        e.preventDefault();

        if (processing) {
            return
        }

        setProcessing(true)

        if (error !== '') {
            return;
        }

        const reqBody = {
            id: product.id,
            name: product.name,
            description: product.description,
            barcode: product.barcode,
            productType: product.productType,
            measureUnit: product.measureUnit,
            priceSell: product.priceSell,
            category: product.category,
            quantity: product.quantity,
            service: product.service,
            displayOrder: product.displayOrder,
            priceSellDiscounts: product.priceSellDiscounts,
        }

        await storeDatabseEntity(reqBody, 'wrh/products', user,
            (e) => { // onError 
                setError(e);
                toast.error(e, { autoClose: false, onClose: (e) => { setError('') } })
                setProcessing(false)
            },
            () => { // onSuccess
                toast.success('Продуктът е запазен', {
                    onOpen: (e) => { setError(''); },
                    autoClose: 3000
                });
                setProcessing(false)
                history('/products');
            })
    }

    const formatDate = (dateStr) => {
        return new Date(dateStr).toLocaleString('bg-BG');
    }

    const onMeasureUnitChange = (e) => { setProduct({ ...product, measureUnit: e }) }
    const onProductCategoryChange = (e) => { setProduct({ ...product, category: e }) }
    const onProductTypeChange = (e) => { setProduct({ ...product, productType: e }) }

    const styles = {
        option: (provided, state) => ({
            ...provided,
            color: "#495057",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#495057"
        })
    };

    return (<>
        {((location?.state?.prod && location?.pathname === '/products/edit') || (!location?.state?.prod && location?.pathname === '/products/add')) ?
            <Card className="border border-dark bg-dark-dpool text-white centerElement">
                <Form onSubmit={onSubmit} id="productForm">
                    <Card.Header>
                        <FontAwesomeIcon icon={faSignInAlt} /> {product?.id ? ('Редактиране на продукт: ' + product.name) : 'Добавяне на продукт'}
                        <span style={{ float: "right" }}>
                            <ActionButton size="sm" variant="success" type="submit"><FontAwesomeIcon icon={faSave} /> Запази</ActionButton>
                            &nbsp;
                            <ActionButton size="sm" variant="danger" type="button"
                                onClick={(e) => { history('/products') }}>Откажи <FontAwesomeIcon icon={faTimesCircle} /></ActionButton>
                        </span>
                    </Card.Header>

                    <Card.Body>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Име</Form.Label>
                            <Col>
                                <Form.Control type="text" name="name" required autoComplete="off"
                                    value={product.name} onChange={(e) => setProduct({ ...product, name: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Описание</Form.Label>
                            <Col >
                                <Form.Control type="text" name="description" autoComplete="off"
                                    value={product.description} onChange={(e) => setProduct({ ...product, description: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Баркод</Form.Label>
                            <Col>
                                <Form.Control type="text" name="barcode" autoComplete="off"
                                    value={product.barcode} onChange={(e) => setProduct({ ...product, barcode: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Тип</Form.Label>
                            <Col>
                                {!product.productType &&
                                    <input tabIndex={-1} autoComplete="off" className="requredSelect" required />
                                }
                                <AsyncSelect loadOptions={getProductTypes} defaultOptions styles={styles} required
                                    value={product.productType} onChange={onProductTypeChange} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Свързана услуга</Form.Label>
                            <Col>
                                <Form.Control type="text" name="service" autoComplete="off"
                                    value={product.service} onChange={(e) => setProduct({ ...product, service: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Мярка</Form.Label>
                            <Col>
                                {!product.measureUnit &&
                                    <input tabIndex={-1} autoComplete="off" className="requredSelect" required />
                                }
                                <AsyncSelect loadOptions={getMeasureUnits} defaultOptions styles={styles}
                                    value={product.measureUnit} onChange={onMeasureUnitChange} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Категория</Form.Label>
                            <Col>
                                {!product.category &&
                                    <input tabIndex={-1} autoComplete="off" className="requredSelect" required />
                                }
                                <AsyncSelect loadOptions={getProductCategories} defaultOptions styles={styles}
                                    value={product.category} onChange={onProductCategoryChange} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Цена (продажна)</Form.Label>
                            <Col>
                                <Form.Control type="text" name="priceSell" autoComplete="off" required
                                    value={product.priceSell} onChange={(e) => setProduct({ ...product, priceSell: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Количество</Form.Label>
                            <Col>
                                <Form.Control type="text" placeholder="0" name="quantity" autoComplete="off"
                                    value={product.quantity} onChange={(e) => setProduct({ ...product, quantity: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Отстъпки</Form.Label>
                            <Col>
                                <Form.Control type="text" placeholder="" name="priceSellDiscounts" autoComplete="off"
                                    value={product.priceSellDiscounts} onChange={(e) => setProduct({ ...product, priceSellDiscounts: e.target.value })} />
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Form.Label column sm="2" >Сортиране</Form.Label>
                            <Col>
                                <Form.Control type="text" placeholder="ZZZZZZ" name="displayOrder" autoComplete="off"
                                    value={product.displayOrder} onChange={(e) => setProduct({ ...product, displayOrder: e.target.value })} />
                            </Col>
                        </Row>

                        {product.id && <>
                            <Form.Row>
                                <Form.Group controlId="formGridAddedOn" as={Col}>
                                    <Form.Label>Добавен от (на)</Form.Label>
                                    <Form.Control type="text" name="createdBy" disabled autoComplete="off" value={getUserTimeInfo(product.createdBy.name, product.dateCreated)} />
                                </Form.Group>
                                <Form.Group controlId="formGridAddedBy" as={Col}>
                                    <Form.Label>Последно редактиран на</Form.Label>
                                    <Form.Control type="text" name="updatedBy" disabled autoComplete="off" value={getUserTimeInfo(product.updatedBy.name, product.dateUpdated)} />
                                </Form.Group>
                            </Form.Row>
                        </>}
                    </Card.Body>
                </Form>
            </Card>
            : <Navigate to={{ pathname: "/products" }} />}
    </>)
}

export default Product
